.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #209ba0 0%, #00467f 100%);

  .box {
    justify-content: space-between;
    padding: 30px 31px;
    flex-direction: column;
    background: white;
    border-radius: 20px;
    align-items: center;
    position: relative;
    display: flex;
    height: 538px;
    width: 367px;

    .languageBox {
      position: absolute;
      top:20px;
      right:20px;
      display: flex;
      gap: 12px;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 1.25px;
      text-align: left;
    }

    .header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }
    .buttons {
      display: flex;
      flex-direction: column;
      gap: 18px;
    }
    .QRSection{
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      .text {
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        color: #343434;
        text-align: center;
      }
      .qrCodes {
        display: flex;
        justify-content: space-between;
      }
      @media screen and (max-width: 1000px) {
        display: none;
      }
      @media screen and (max-height: 540px) {
        display: none;
      }
    }

    @media screen and (max-width: 1000px) {
      width: 312px;
      height: 328px;
    }

    @media screen and (max-height: 540px) {
      width: 312px;
      height: 328px;
    }
  }
}

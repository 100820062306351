.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #209ba0 0%, #00467f 100%);

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    width: 867px;
    min-height: 538px;
    gap: 30px;

    .box {
      padding: 30px;
      border-radius: 20px;
      background: white;

      .header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin-bottom: 20px;

        img {
          width: 169px;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .text {
          font-style: normal;
          font-size: 15px;
          line-height: 22px;
          color: #343434;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      gap: 30px;

      .contentTitlu {
        font-weight: 500;
        font-style: normal;
        font-size: 20px;
        line-height: 24px;
        color: white;
        text-align: center;
        margin-bottom: 15px;
      }

      .contentWrapper {
        display: flex;
        justify-content: space-between;
        gap: 30px;

        .facilitati {
          flex: 3 5;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .list {
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 12px;

            li {
              position: relative;
              font-size: 15px;
              line-height: 18px;
              padding-left: 30px;
              color: white;

              &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 8px;
                height: 8px;
                background: #f05135;
                border-radius: 50%;
              }
            }
          }
        }

        .download {
          flex: 2 5;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 15px;
          margin-top: auto;

          @media screen and (max-width: 768px) {
            margin-top: 30px;
          }

          .titlu {
            font-weight: 400;
            font-style: normal;
            text-align: center;
            color: white;
            font-size: 18px;
            line-height: 22px;
          }

          .store {
            display: flex;
            gap: 20px;
            justify-content: center;
            align-items: center;

            img {
              width: 120px;
            }
          }

          .qrWrapper {
            background: white;
            padding: 8px;
            width: min-content;

            @media screen and (max-width: 768px) {
              display: none;
            }

          }
        }

        @media screen and (max-width: 768px) {
          display: block;
        }
      }
    }

    @media screen and (max-width: 940px) {
      width: 100%;
      padding: 50px 20px;
    }
  }

  @media screen and (max-width: 768px) {
    height: auto;
  }
}

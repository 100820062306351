@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  src: local('Roboto'), url('../fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: local('Roboto'), url('../fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: local('Roboto'), url('../fonts/Roboto-SemiBold.ttf') format('truetype');
}

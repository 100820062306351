.link {
  text-decoration: none;
  color: #4bafb1;
  background: none;
  border: 2px solid #4bafb1;
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.25px;
  padding: 11px 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  width: 250px;
  height: 36px;
  border-radius: 20px;

  &:hover {
    background: #4bafb1;
    color: #fff;
  }

  @media screen and (max-width: 1000px) {
    width: 262px;
  }
  @media screen and (max-height: 540px) {
    width: 262px;
  }
}

.container {
  width: 100%;
  display: flex;
  color: #343434;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #209ba0 0%, #00467f 100%);

  @media screen and (min-width: 768px) {
    height: 100vh;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 30px;
    margin: 30px auto;
    background: white;
    border-radius: 20px;
    width: 480px;
    gap: 30px;

    .languageBox {
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      gap: 12px;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 1.25px;
      text-align: left;
    }

    .header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin-bottom: 20px;
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .text {
        font-size: 16px;
        line-height: 20px;
        text-align: center;

        span {
          display: block;
          margin: 20px 0 10px 0;
          font-size: 15px;
          line-height: 18px;
        }
      }
    }

    .newApp {
      display: flex;
      flex-direction: column;
      align-items: center;

      .text {
        font-style: normal;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 10px;
        text-align: center;
      }
    }

    .question {
      display: flex;
      flex-direction: column;
      align-items: center;

      .text {
        font-style: normal;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 10px;
        text-align: center;
      }

      .num {
        display: flex;
        align-items: center;

        span {
          margin-left: 10px;
          color: #4bafb1;
          margin-right: 5px;

        }

        font-style: normal;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 10px;
        text-align: center;
      }
    }

    .divider {
      width: 100%;
      border-top: 2px solid #f7f1f1;
      border-radius: 5px;
    }

    @media screen and (max-width: 1000px) {
      width: 312px;
    }
  }
}

.languageSwitcher {
  position: relative;
  display: inline-block;

  .dropdownToggle {
    display: flex;
    align-items: center;
    gap: 8px;
    background: #f7f1f1;
    border: 1px solid #f7f1f1;
    padding: 6px 12px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    color: black;
    transition: all 0.2s ease;
    min-width: 40px;

    &:hover {
      background: white;
    }

    &:focus {
      outline: none;
    }

    .currentLang {
      flex: 1;
      text-align: left;
    }

    .arrow {
      font-size: 10px;
      transition: transform 0.2s ease;
      color: white;

      transform: rotateX(0deg);

    }

    &.active {
      background: white;

      .arrow {
        transform: rotateX(180deg);
      }
    }

  }

  .dropdownMenu {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    right: 0;
    background: white;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    border: 1px solid rgba(0, 0, 0, 0.15);
    overflow: hidden;
    z-index: 1000;
    min-width: 40px;

    .languageOption {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      padding: 6px 12px;
      border: none;
      background: none;
      cursor: pointer;
      font-size: 14px;
      color: black;
      transition: background 0.2s ease;
      text-align: left;

      &:hover {
        background: #f7f1f1;
        color: black;
      }

      &.active {
        background: #f7f1f1;
        color: black;
      }
    }
  }
}


@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

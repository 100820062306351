.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #209ba0 0%, #00467f 100%);

  .box {
    justify-content: space-between;
    padding: 30px 20px;
    flex-direction: column;
    background: white;
    border-radius: 20px;
    align-items: center;
    position: relative;
    display: flex;
    height: auto;
    width: 367px;

    .languageBox {
      position: absolute;
      top:20px;
      right:20px;
      display: flex;
      gap: 12px;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 1.25px;
      text-align: left;
    }
    .header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }

    .info {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      align-items: center;

      .title {
        font-size: 20px;
      }

      .text {
        margin-top: 20px;
        font-size: 16px;
        line-height: 20px;
        text-align: center;

        span {
          display: block;
          margin: 20px 0 10px 0;
          font-size: 14px;

        }
      }
    }

    @media screen and (max-width: 1000px) {
      width: 312px;
    }

    @media screen and (max-height: 540px) {
      width: 312px;
    }
  }
}
